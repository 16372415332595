import { useIdFromUrl } from '@payler/payment-page-ui-shared';
import { assert, useDebounce } from '@payler/utils';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../context/config-context';
import { useEffect, useMemo, useState } from 'react';

const STALE_TIME = 600_000;

export const useAvailableTokens = () => {
  const sessionId = useIdFromUrl();
  const api = useApi();
  assert(!!sessionId, 'Session id can not be empty or undefined');

  const { data, isLoading } = useQuery({
    queryKey: ['available_tokens'],
    queryFn: async () => {
      const resp = await api.getAvailableTokens(sessionId);
      return resp?.data;
    },
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  });

  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export const useSearchTokens = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { data } = useAvailableTokens();
  const [searchedTokens, setSearchedTokens] = useState(data);

  const debouncedSearchValue = useDebounce(searchValue, 300, '');

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length && data?.length) {
      const searchedValueInLowercase = debouncedSearchValue.toLowerCase();
      setSearchedTokens(
        data.filter((token) =>
          token.symbol.toLowerCase().startsWith(searchedValueInLowercase, 0),
        ),
      );
    } else {
      setSearchedTokens(data);
    }
  }, [data, debouncedSearchValue]);

  return {
    searchValue,
    onSearch: setSearchValue,
    searchedData: searchedTokens,
  };
};
