import { useIdFromUrl } from '@payler/payment-page-ui-shared';
import { useApi } from '../context/config-context';
import { assert } from '@payler/utils';
import { TPaymentForm, TPaymentSession } from '@payler/payment-page-api-crypto';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const STALE_TIME = 600_000;

export const usePaymentSessionQuery = () => {
  const sessionId = useIdFromUrl();
  const api = useApi();
  assert(!!sessionId, 'Session id can not be empty or undefined');

  return useQuery<TPaymentSession>({
    queryKey: ['payment_session', sessionId],
    queryFn: async () => {
      const response = await api.getPaymentSession(sessionId);
      return response.data;
    },
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME,
    retry: false,
    structuralSharing: false,
  });
};

export const useInvalidatePaymentSessionQuery = () => {
  const sessionId = useIdFromUrl();
  assert(!!sessionId, 'Session id can not be empty or undefined');
  const clientQuery = useQueryClient();

  return async () => {
    if (!clientQuery.isFetching({ queryKey: ['payment_session', sessionId] })) {
      await clientQuery.invalidateQueries({
        queryKey: ['payment_session', sessionId],
      });
    }
  };
};

export const usePaymentSessionForm = () => {
  const { isLoading, data } = usePaymentSessionQuery();

  return { isLoading, data: data?.paymentForm || ({} as TPaymentForm) };
};

export const usePaymentSessionPresetData = () => {
  const { data } = usePaymentSessionForm();

  return { tokenCode: data?.presetToken, networkType: data?.networkType };
};

export const usePaymentSessionStatus = () => {
  const { data } = usePaymentSessionForm();

  return data?.status;
};

export const useSiteUrl = () => {
  const { data } = usePaymentSessionForm();

  return data?.returnUrl;
};
