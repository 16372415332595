import React, { FC } from 'react';
import {
  FullPageLoader,
  LayoutContextProvider,
  NotFound,
} from '@payler/payment-page-ui-shared';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CryptoPaymentScreen } from '../screens/crypto-payment-screen';
import { CryptoPaymentForm } from './crypto-payment-form';
import {
  CryptoPaymentPageConfigContextProvider,
  TCryptoPaymentEnv,
} from '../context/config-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraWrapper } from './wrappers/chakra-wrapper';
import { GlobalTimerContextProvider } from '@payler/payment-page-ui-shared';
import { ErrorBoundary } from './error-boundary';

const queryClient = new QueryClient();

export const CryptoPaymentApp: FC<{ env: TCryptoPaymentEnv }> = ({ env }) => (
  <GlobalTimerContextProvider>
    <ChakraWrapper>
      <HelmetProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <CryptoPaymentPageConfigContextProvider env={env}>
              <QueryClientProvider client={queryClient}>
                <LayoutContextProvider>
                  <React.Suspense fallback={<FullPageLoader />}>
                    <Routes>
                      <Route
                        path="crypto-payment-page"
                        element={<CryptoPaymentScreen />}
                      >
                        <Route path=":id" element={<CryptoPaymentForm />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </React.Suspense>
                </LayoutContextProvider>
              </QueryClientProvider>
            </CryptoPaymentPageConfigContextProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </HelmetProvider>
    </ChakraWrapper>
  </GlobalTimerContextProvider>
);
